import React, { useState } from "react";
import RoundedEdgeButton from "../interactives/RoundeEdgeButton";
import ButtonContainer from "../interactives/Buttoncontainer";
import "./sortContainer.css";

export const SortContainer = ({ onSortChange, onClose }) => {
    const [selectedSort, setSelectedSort] = useState(null);

    const sortOptions = [
        { label: "Newest", value: "newest" },
        { label: "Oldest", value: "oldest" },
        { label: "Most Liked", value: "mostLiked" },
    ];

    const handleSortClick = (sortValue) => {
        setSelectedSort(sortValue);
        if (onSortChange) {
            onSortChange(sortValue);
        }
    };

    return (
        <div className="sort-column">
            <ButtonContainer title="Sort By:" onClose={onClose} />
            <div className="small-icon-button">
                {sortOptions.map((option, index) => (
                    <RoundedEdgeButton
                        key={index}
                        label={option.label}
                        bulletColor={selectedSort === option.value ? "red" : "white"}
                        onClick={() => handleSortClick(option.value)}
                        className={selectedSort === option.value ? "selected" : ""}
                    />
                ))}
            </div>
        </div>
    );
};

export default SortContainer;

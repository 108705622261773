import React from "react";
import "./ProfileBanner.css";
import { FaEdit } from 'react-icons/fa';

export const ProfileBanner = () => {
    return (
        <div className="profile-header">
            <div className="group">
                <FaEdit className="large-pencil" />
            </div>
            <div className="frame">
                <div className="artists-name">ARTISTS NAME</div>
                <div className="publishings">10000 Publishings</div>
                <div className="sincedate">Since 2024</div>
            </div>
            <FaEdit className="small-pencil" />
        </div>
    );
};

export default ProfileBanner;
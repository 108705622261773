import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const { user } = useAuthenticator(); // Gets the current authenticated user
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        if (user && user.attributes) {
            setCurrentUser({
                username: user.username,
                userId: user.attributes.sub, // Assuming 'sub' is the user ID
                email: user.attributes.email,
            });
        } else {
            setCurrentUser(null);
        }
    }, [user]);

    return (
        <UserContext.Provider value={{ currentUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);

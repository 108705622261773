import React, { useEffect, useState } from 'react';
import ProfileControlBar from '../components/ProfileControlBar/ProfileControlBar';
import Content from '../components/Content/Content';
import ProfileBanner from '../components/ProfileBanner/ProfileBanner';
import { CategoryContainer } from '../components/CategoryContainer/CategoryContainer';
import { SortContainer } from '../components/SortContainer/SortContainer'; // Import SortContainer

const ProfilePage = ({ sortOption, onSortChange }) => {
    const [images, setImages] = useState([]); // Assume this state holds images specific to the user profile
    const [sortedImages, setSortedImages] = useState([]);

    useEffect(() => {
        const sorted = [...images];
        switch (sortOption) {
            case 'oldest':
                sorted.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                break;
            case 'newest':
                sorted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                break;
            case 'mostLiked':
                // Placeholder logic for most liked sorting
                break;
            default:
                sorted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
        setSortedImages(sorted);
    }, [sortOption, images]);

    return (
        <div className="profile-page">
            <ProfileBanner />
            <ProfileControlBar sortOption={sortOption} onSortChange={onSortChange} />
            <CategoryContainer /> {/* Add CategoryContainer here */}
            <SortContainer /> {/* Add SortContainer for testing */}
            <Content images={sortedImages} />
        </div>
    );
};

export default ProfilePage;

import React from 'react';
import { ColRightContainer } from '../ColRightContainer/ColRightContainer';
import './ImageModal.css';

const ImageModal = ({ image, creatorName, uploadDate, title, description, onClose }) => {
    return (
        <div className="image-modal">
            <div className="modal-content-wrapper container">
                <div className="row">
                    {/* Image Column */}
                    <div className="col-lg-8 col-12 modal-image-container">
                        <img src={image} alt={title} className="modal-image" />
                    </div>
                    {/* Details Column */}
                    <div className="col-lg-4 col-12">
                        <ColRightContainer
                            creatorName={creatorName}
                            uploadDate={uploadDate}
                            title={title}
                            description={description}
                            onClose={onClose}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageModal;

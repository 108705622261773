import React from 'react';
import './Banner.css';
import bannerImage from '../../images/banner.jpg';
import { FaSearch } from 'react-icons/fa'; // Import the search icon

const Banner = () => {
    return (
        <section className="banner">
            <img src={bannerImage} alt="Monstrous Legends Banner" className="banner-image" />
            <div className="banner-content">
                <div className="banner-left">
                    <h1>MONSTROUS</h1>
                    <h1>LEGENDS</h1>
                </div>
                <div className="banner-right">
                    <a href="https://www.amazon.com/Monstrous-Legends-2-book-series/dp/B0CW19R8NV" target="_blank" rel="noopener noreferrer">
                        <button className="cta-button">VIEW COLLECTION</button>
                    </a>
                </div>
                {/* Add the search bar here */}
                <div className="search-bar">
                    <FaSearch className="search-icon" />
                    <input type="text" placeholder="Search" />
                </div>
            </div>
        </section>
    );
};

export default Banner;

import React from 'react';
import './ProfileControlBar.css';

const ProfileControlBar = ({ sortOption, onSortChange, onViewChange }) => {
    return (
        <div className="profile-control-bar">
            <div className="view-options">
                <button onClick={() => onViewChange('bio')}>View Bio</button>
                <button onClick={() => onViewChange('content')}>View Content</button>
            </div>
            <div className="sort-options">
                <select
                    value={sortOption}
                    onChange={(e) => onSortChange(e.target.value)}
                    className="sort-select"
                >
                    <option value="newest">Newest</option>
                    <option value="oldest">Oldest</option>
                    <option value="mostLiked">Most Liked (Placeholder)</option>
                </select>
            </div>
        </div>
    );
};

export default ProfileControlBar;

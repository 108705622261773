import React, { useState, useContext, useEffect } from 'react';
import ImageModal from '../ImageModal/ImageModal';
import './Content.css';
import { ImageContext } from '../../contexts/ImageContexts';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchImages as fetchImagesAPI } from '../../utils/api';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Content = ({ filterByUser = false }) => {
    const { images, addImages, hasMore, setHasMore } = useContext(ImageContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState(null);
    const [lastKey, setLastKey] = useState(null);

    const { user } = useAuthenticator((context) => [context.user]);


    useEffect(() => {
        if (!filterByUser || (filterByUser && user)) {
            fetchImages(1, 20, null, filterByUser, 'newest');
        }
    }, [filterByUser, user]);



    const handleClick = (item) => {
        setSelectedItem(item);
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
    };

    const [isFetching, setIsFetching] = useState(false);

    const fetchImages = async () => {
        if (!hasMore || isFetching) {
            if (!hasMore) console.log("No more images to load.");
            if (isFetching) console.log("Already fetching images.");
            return;
        }

        setIsFetching(true);
        console.log('Before Fetch - Current Page:', currentPage);
        console.log('Before Fetch - Last Key:', lastKey);
        console.log('Before Fetch - Has More:', hasMore);

        try {
            const data = await fetchImagesAPI(currentPage, 50, lastKey, filterByUser); 
            console.log('Fetched Data:', data);

            if (data.items.length === 0) {
                console.log("No items returned, setting hasMore to false");
                setHasMore(false);
                return;
            }

            console.log('Fetched Images:', data.items);
            console.log('Existing Images:', images);
            const newImages = data.items.filter((image) => !images.some((img) => img.key === image.key));
            console.log('New Images After Filtering:', newImages);

            addImages(newImages);
            setLastKey(data.lastKey);

            setCurrentPage((prevPage) => {
                const nextPage = prevPage + 1;
                console.log('Next Page:', nextPage);
                return nextPage;
            });

            console.log('New Images Added:', newImages.length);
            console.log('Updated Last Key:', data.lastEvaluatedKey);

            if (!data.lastEvaluatedKey) {
                setHasMore(false);
                console.log("No more data available, setting hasMore to false");
            }
        } catch (error) {
            console.error('Error fetching more images:', error);
            setHasMore(false);
        } finally {
            setIsFetching(false);
        }
    };

    return (
        <section className="content-section">
            <h2 className="active-category">{filterByUser ? 'Your Uploaded Images' : 'Category Name Currently Active'}</h2>
            <InfiniteScroll
                dataLength={images.length} // Ensure this reflects the sorted images
                next={fetchImages}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={<p style={{ textAlign: 'center' }}><b>No more images to show</b></p>}
            >
                <div className="content-grid">
                    {images.map((item, index) => (
                        <div key={item.imageId} className="content-item" onClick={() => handleClick(item)}>
                            <div className="image-wrapper">
                                <img
                                    src={item.imageUrl}
                                    alt={item.title}
                                    className="content-image"
                                    loading="lazy"
                                />
                            </div>
                            <div className="content-info">
                                <p>{item.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </InfiniteScroll>

            {selectedItem && (
                <ImageModal
                    image={selectedItem.imageUrl}  // Corrected from selectedItem.url
                    title={selectedItem.title}
                    description={selectedItem.description}
                    creatorName={selectedItem.creatorName}
                    uploadDate={selectedItem.uploadDate}
                    onClose={handleCloseModal}
                />
            )}

        </section>

    );
};

export default Content;


import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import placeholderImage from "../../images/logo.png"; // Placeholder image import
import "./colRightContainer.css";

export const ColRightContainer = ({ creatorName, uploadDate, profileImageUrl, onClose, likesCount, title, description }) => {
    return (
        <div className="col-right-container">
            <div className="header-row">
                {/* Profile Picture Column */}
                <div className="profile-column">
                    <div className="profile-pic">
                        <img
                            className="profile-pic-image"
                            alt="Profile"
                            src={profileImageUrl ? profileImageUrl : placeholderImage}
                        />
                    </div>
                </div>
                {/* Creator Information Column */}
                <div className="info-column">
                    <div className="creator-row">
                        <div className="creator-name">{creatorName}</div>
                    </div>
                    <div className="publish-date-row">
                        <div className="publish-date">Date Published: {uploadDate}</div>
                    </div>
                    <div className="like-button-row">
                        <div className="BTN-like">
                            <FontAwesomeIcon icon={faThumbsUp} />
                            <div className="text">{likesCount}</div>
                        </div>
                    </div>
                </div>
                {/* Close Button */}
                <button className="column-close" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
            {/* Scrollable Content Section */}
            <div className="content-section">
                {/* Image Title */}
                <div className="image-title-row">
                    <div className="image-title">{title}</div>
                </div>
                {/* Description */}
                <div className="description-row">
                    <p className="description-of-image">{description}</p>
                </div>
            </div>
        </div>
    );
};

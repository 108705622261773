import React, { useState, useContext } from 'react';
import { FaFilter, FaSort } from 'react-icons/fa';
import './ControlBar.css';
import SortContainer from '../SortContainer/SortContainer';
import CategoryContainer from '../CategoryContainer/CategoryContainer';
import { ImageContext } from '../../contexts/ImageContexts';
import axios from 'axios';

const ControlBar = () => {
    const [showSortPanel, setShowSortPanel] = useState(false);
    const [showCategoryPanel, setShowCategoryPanel] = useState(false); // State to show/hide category panel
    const [sortedItems, setSortedItems] = useState([]);
    const { addImages } = useContext(ImageContext);

    // Toggle the sort panel
    const handleSortClick = () => {
        setShowSortPanel((prevState) => !prevState);
        setShowCategoryPanel(false); // Close category panel if open
    };

    // Toggle the category panel
    const handleFilterClick = () => {
        setShowCategoryPanel((prevState) => !prevState);
        setShowSortPanel(false); // Close sort panel if open
    };

    // Close the category panel
    const handleCloseCategoryPanel = () => {
        setShowCategoryPanel(false);
    };

    const handleSortChange = async (sortValue) => {
        try {
            const baseURL = process.env.REACT_APP_API_BASE_URL;
            console.log("API Base URL:", baseURL);

            // Use the existing /api/images route with sort parameters
            const response = await axios.get(`${baseURL}/api/images`, {
                params: {
                    sortOption: sortValue,  // Pass the sorting option
                    category: 'all',        // Pass the category (if needed)
                }
            });

            console.log('API Response:', response.data);

            if (Array.isArray(response.data.items)) {
                // Filter out duplicates using the imageId
                const uniqueItems = response.data.items.filter((item, index, self) =>
                    index === self.findIndex((t) => t.imageId === item.imageId)
                );

                if (uniqueItems.length < response.data.items.length) {
                    console.warn("Duplicate ids found in sortedItems and filtered out.");
                }

                addImages(uniqueItems);
            } else {
                console.error("Invalid data format received from API");
                setSortedItems([]); // Reset to an empty array in case of error
            }
        } catch (error) {
            console.error("Failed to sort items:", error);
            setSortedItems([]); // Set to empty array on failure to avoid undefined state
        }
    };


    return (
        <div className="control-bar">
            <div className="control-bar-left">
                {/* COMMENTED OUT FILTER */}
                {/* <FaFilter className="control-bar-icon" onClick={handleFilterClick} /> */}
                <FaSort className="control-bar-icon" onClick={handleSortClick} />
            </div>

            {/* Render Category Panel */}
            {showCategoryPanel && (
                <div className="category-panel">
                    <CategoryContainer onClose={handleCloseCategoryPanel} />
                </div>
            )}

            {/* Render Sort Panel */}
            {showSortPanel && (
                <div className="sort-panel">
                    <SortContainer onSortChange={handleSortChange} onClose={() => setShowSortPanel(false)} />
                </div>
            )}
        </div>
    );
};

export default ControlBar;
